import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import { useTranslation } from "react-i18next";

interface ISearchProps {
	value: string;
	onChange: (searchTerm: string) => any
}

function Search(props: ISearchProps) {
	const { t } = useTranslation();

	const onClear = () => props.onChange("");

	return (
		<div style={{ padding: "10px", display: "flex" }}>
			<input
				type="text"
				value={props.value}
				onChange={e => props.onChange(e.target.value)}
				className={`form-control`}
				placeholder={t("common.search")}
			/>
			{props.value &&
				<IconButton aria-label="delete" style={{ cursor: "pointer" }} onClick={onClear}>
					<ClearIcon />
				</IconButton>
			}
		</div>);
}

export { Search };