import { UoW, Data, Link, useAuthUser, useEffect, Helpers } from 'globalImport';

import { FieldValues, useForm } from "react-hook-form";

import { FormInput, FormSubmitButton, FormContainer, ShowErrorMessage, ShowSuceededMessage } from '../_components';
import { YupValidationBuilder } from './Helpers/YupValidationBuilder';

function Register() {
	const auth = useAuthUser();
	const authUser = auth?.user;
	const registerAction = UoW.Users.useRegister();

	useEffect(() => {
		// redirect to home if already logged in
		if (authUser) Helpers.history.navigate!('/shop');
	}, [authUser]);

	const formOptions = { resolver: YupValidationBuilder("email", "gsm", "firstname", "lastname", "password", "confirmPassword") };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, formState } = useForm(formOptions);
	const { errors } = formState;

	async function onSubmit(fields: FieldValues) {
		registerAction.execute({
			...fields,
			acceptTerms: true
		} as Data.User.IRegisterRequest);
	}

	if (registerAction.hasSuceeded) return (
		<FormContainer title="login.register.formTitle">
			<ShowSuceededMessage action={registerAction}>
				<br />
				<Link className="btn btn-primary" to="/shop/login" relative="path">Inloggen</Link>
			</ShowSuceededMessage>
		</FormContainer>
	);

	return (
		<FormContainer title="login.register.formTitle">
			<form onSubmit={handleSubmit(onSubmit)}>

				<FormInput label="login.email" name="email" register={register} errors={errors} />

				<FormInput label="login.gsm" name="gsm" register={register} errors={errors} />

				<FormInput label="login.firstname" name="firstname" register={register} errors={errors} />

				<FormInput label="login.lastname" name="lastname" register={register} errors={errors} />

				<FormInput label="login.newPassword" name="password" type="password" register={register} errors={errors} />

				<FormInput label="login.passwordConfirm" name="confirmPassword" type="password" register={register} errors={errors} />

				<FormSubmitButton
					label="login.register.registerButton"
					executing={registerAction.isLoading} />

				<ShowErrorMessage action={registerAction} />
			</form>
		</FormContainer>
	)
}

export { Register };