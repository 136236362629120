import { mui, Models, config, useTranslation, useEffect } from 'globalImport';
import { Line, MoneyString } from '_components';

import { ICartItem, ICartItemOption } from '../cart/ICartItem';
import { AddItemOption } from './AddItemOption';


export function AddItemForm(props: {
	onChange: (cardItem: ICartItem) => any,
	product: Models.IProduct,
	cartItem: ICartItem
}) {
	const { t } = useTranslation();
	const p = props.product;

	useEffect(() => {
		onOptionChange(null);
	}, []);

	const onChange = (field: string, value: any) => {
		const cartItem = { ...props.cartItem } as ICartItem;
		cartItem[field] = value;
		props.onChange(cartItem);
	}

	const handleDependecies = (options: ICartItemOption[]) => {
		for (let value of options) {
			// Check if we have dependencies
			const dependingOnOptions = options.filter(o =>
				o.optionGroup.dependecyOptionGroupId === value.optionGroup.id &&
				o.optionGroup.dependecyOptionGroupOptionId !== value.option?.id);

			// Set value to null if not the correct option was selected
			for (let optionValue of dependingOnOptions) {
				optionValue.option = null
			}

			// Check if we have except
			const exceptOnOptions = options.filter(o =>
				o.optionGroup.exceptOptionGroupId === value.optionGroup.id &&
				o.optionGroup.exceptOptionGroupOptionId === value.option?.id);

			// Set value to null if not the correct option was selected
			for (let optionValue of exceptOnOptions) {
				optionValue.option = null
			}
		}
	}

	const onOptionChange = (value: ICartItemOption | null) => {
		const cartItem = { ...props.cartItem } as ICartItem;

		if (value) {
			cartItem.options = cartItem.options.filter(o => o.optionGroup.id !== value.optionGroup.id);
			cartItem.options.push(value);

			handleDependecies(cartItem.options);
		}

		let priceAfterOptios = props.product.price;
		// Check if we have a new base price
		if (cartItem.options.filter(o => o.option && o.option.newBasePrice).length === 1) {
			priceAfterOptios = cartItem.options.filter(o => o.option && o.option.newBasePrice)[0].option?.newBasePrice!;
		}

		for (const o of cartItem.options) {
			priceAfterOptios += (o.option && o.option.priceDifference) || 0;
		}
		cartItem.price = priceAfterOptios;

		props.onChange(cartItem);
	}

	const handleQuantityChanged = (e) => {
		let q = parseInt(e.target.value);
		if (!q) q = 1;
		if (q < 1) q = 1;

		onChange("quantity", q);
	}

	return (
		<>

			{p.options.map(o => <AddItemOption
				key={o.id}
				optionGroup={o}
				selected={props.cartItem.options.find(v => v.optionGroup.id === o.id)}
				onChange={onOptionChange}
				allOptions={props.cartItem.options}
			/>)}

			{p.allowRemark &&
			<>
				<mui.FormLabel htmlFor="remark-TextField">{t('shop.menuItem.remarks')}</mui.FormLabel>
				<mui.TextField
					id="remark-TextField"
					multiline
					fullWidth
					rows={4}
					onChange={(e) => onChange("remark", e.target.value)}
					value={props.cartItem.remark}
					/>
			</>}


			<mui.FormLabel>{t('shop.menuItem.quantity')}</mui.FormLabel>
			<mui.TextField
				type="number"
				required
				fullWidth
				variant="outlined"
				onChange={handleQuantityChanged}
				value={props.cartItem.quantity}
			/>

			<Line />
			{config.showPrices &&  
				<div style={{ textAlign: 'right' }}>
					{props.cartItem.quantity} x <MoneyString value={props.cartItem.price} /> = <MoneyString value={props.cartItem.price * (props.cartItem.quantity || 0)} />
				</div>
			}
			
		</>);
}