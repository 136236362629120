import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React from 'react';
import { ErrorMessage, FormCancelButton, ShowErrorMessage, ShowOnSuceeded } from '../../_components';
import { useForm } from 'react-hook-form';
import { IOptionGroupOption } from '../../_data';
import { FormContainerPopin, FormInput, FormSubmitButton } from '../../_components';
import * as data from '../../_data';
import { Button } from '@mui/material';

export interface OptionGroupOptionEditResult {
	action: "new" | "updated" | "canceled";
	option: IOptionGroupOption | null;
}

export interface IOptionGroupOptionEditProps {
	groupId: number | string;
	option?: IOptionGroupOption | null;
	action: data.IApiData<IOptionGroupOption, data.GroupOption.submitOptionModel>;
	onClose: (result: OptionGroupOptionEditResult) => any;
}

export function OptionGroupOptionEdit(props: IOptionGroupOptionEditProps) {

	const [showForm, setShowForm] = React.useState(false);
	const [currentEditOption, setCurrentEditOption] = React.useState(null as null | IOptionGroupOption);

	// form validation rules
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required.')
	});
	const formOptions = { resolver: yupResolver(validationSchema) };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, formState, setValue, reset } = useForm(formOptions);
	const { errors } = formState;

	React.useEffect(() => {
		if (!props.option) {
			return;
		}
		setCurrentEditOption(props.option);
		for (let key in props.option) {
			setValue(key, props.option[key]);
		}
	}, [props.option, setValue]);

	function onSubmit(fields: any) {
		const data = {
			groupId: props.groupId, option: fields as IOptionGroupOption
		}

		props.action.execute(data, d => {
			props.onClose({
				option: d,
				action: props.option ? "updated" : "new"
			} as OptionGroupOptionEditResult);
			onClose();
		});
	}

	function onClose() {
		reset();
		setShowForm(false);
		setCurrentEditOption(null);

		props.onClose({
			option: null,
			action: "canceled"
		} as OptionGroupOptionEditResult);
	}

	if (!showForm && !currentEditOption) return (
		<>
			<Button onClick={() => setShowForm(true)} >
				Add new
			</Button>
			<ShowOnSuceeded action={props.action}>
				<div className="alert alert-primary mt-3 mb-0">
					Option created
				</div>
			</ShowOnSuceeded>
		</>
	);

	return (
		<FormContainerPopin title="Edit Group Option">

			<ShowErrorMessage action={props.action} />

			<form onSubmit={handleSubmit(onSubmit)}>

				<FormInput label="model.optionGroupOption.name" name="name" register={register} errors={errors} />

				<FormInput label="model.optionGroupOption.priceDifference" name="priceDifference" register={register} errors={errors} />

				<FormInput label="model.optionGroupOption.newBasePrice" name="newBasePrice" register={register} errors={errors} />

				<FormInput label="model.optionGroupOption.isDefault" type="checkbox" name="isDefault" register={register} errors={errors} />


				<FormSubmitButton
					label="common.save"
					executing={props.action.isLoading} />

				<FormCancelButton onClick={onClose} />

			</form>
		</FormContainerPopin>

	);
}
