import { useTranslation } from "../globalImport";



interface ISingleValueProp<P> {
    value: P | undefined
}

export function MoneyString(props: ISingleValueProp<number | null>) {
    const formatter = new Intl.NumberFormat('fr-BE', { // fr to put currency at the end
        style: 'currency',
        currency: 'EUR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    if (!props.value) return <span></span>;
    return <span>{formatter.format(props.value)}</span>
}

export function DateString(props: ISingleValueProp<Date | undefined>) {
    if (!props.value) return <span></span>;
    const v = typeof (props.value) === "string" ? new Date(props.value) : props.value;
    return <span>{v.toLocaleDateString('nl-BE')}</span>
}

export function DateTimeString(props: ISingleValueProp<Date | undefined>) {
    if (!props.value) return <span></span>;
    const v = typeof (props.value) === "string" ? new Date(props.value) : props.value;

    const time = v.toLocaleTimeString('nl-BE');
    //remove seconds
    const timeParts = time.split(":");
    const timeWithoutSeconds = timeParts[0] + ":" + timeParts[1];
    return <span>{v.toLocaleDateString('nl-BE') + " " + timeWithoutSeconds}</span>;
}


export function Trans(props: ISingleValueProp<string>) {
    const { t } = useTranslation();
    if (!props.value) return <span></span>;
    return <span>{t(props.value)}</span>
}