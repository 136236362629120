import { NavigateFunction } from "react-router-dom";

// custom history object to allow navigation outside react components
export const history = {
    navigate: null,
    location: null
} as
    {

    navigate: NavigateFunction | null,
    location: any | null
    };