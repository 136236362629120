import { useTranslation, config, useUser, useEffect, useState } from "globalImport";

const maxOrderDateDelivery = new Date((new Date()).setHours(10, 30, 0, 0)); //10
const maxOrderDate = new Date((new Date()).setHours(11, 30, 0, 0)); //11:30
const maxOrderDateFormated = ("0" + maxOrderDate.getHours()).slice(-2) + ":" + ("0" + maxOrderDate.getMinutes()).slice(-2)
//const allowNextDate = new Date((new Date()).setHours(18, 0, 0, 0));

function OrderTimeWarning(props: { hideWhenOnTime?: boolean }) {
	const { t } = useTranslation();
	const [time, setTime] = useState(Date.now());

	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 1000);

		return () => clearInterval(interval);
	}, []);

	if (maxOrderDate < new Date(time)) {
		return <div className="alert alert-danger mt-3 mb-0">
			{t("shop.orderIsTolate", { maxOrderDateFormated })}

		</div>
	}
	else if (maxOrderDateDelivery < new Date(time)) {
		return <div className="alert alert-warning mt-3 mb-0">
			{t("shop.orderOnlyPickup", { maxOrderDateFormated })}

		</div>
	}
	else {
		if (props.hideWhenOnTime) return <></>;

		return <div className="alert alert-info mt-3 mb-0">
			{t("shop.orderIsOnTime", { maxOrderDateFormated })}
			
		</div>
	}
}

function OrderForDeliveryTimeVisible({ children }) {
	const [time, setTime] = useState(Date.now());
	const authUser = useUser();

	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 1000);

		return () => clearInterval(interval);
	}, []);

	if (maxOrderDateDelivery > new Date(time) || authUser?.role === "Admin") {
		return <>
			{children}
			{authUser?.role === "Admin" && <>
				<br />
				<small>Zichtbaar omdat u admin bent.</small>
			</>}
		</>
	}
	return <></>
}
function OrderTimeVisible({ children }) {
	const [time, setTime] = useState(Date.now());
	const authUser = useUser();

	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 1000);

		return () => clearInterval(interval);
	}, []);

	if (maxOrderDate > new Date(time) || authUser?.role === "Admin") {
		return <>
			{children}
			{authUser?.role === "Admin" && <>
				<br />
				<small>Zichtbaar omdat u admin bent.</small>
			</>}
		</>
	}
	return <></>
}

export { OrderTimeWarning, OrderTimeVisible, OrderForDeliveryTimeVisible };