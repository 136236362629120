import { CSSProperties, HTMLInputTypeAttribute } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IFormInputFieldProps {
	label: string;
	name: string;
	register: UseFormRegister<FieldValues>;
	errors: FieldErrors<FieldValues>;
	defaultValue?: string | undefined | null;
	type?: HTMLInputTypeAttribute | undefined | null;
}

interface IFromSubmitButtonProps {
	label: string;
	executing: boolean;
}

interface IFromCancelButtonProps {
	label?: string;
	executing?: boolean;
	onClick: () => any;
}

function FormInput(props: IFormInputFieldProps ) {
	const { t } = useTranslation();

	return (
		<div className="form-group">
			<label>{t(props.label)}</label>
			<input
				defaultValue={props.defaultValue || undefined}
				type={props.type || "text"}
				{...props.register(props.name)}
				name={props.name}
				className={`form-control ${props.errors[props.name] ? 'is-invalid' : ''}`} />
			<div className="invalid-feedback">{(props.errors as any)[props.name]?.message}</div>
		</div>);
			
}

function FormSubmitButton(props: IFromSubmitButtonProps) {
	const { t } = useTranslation();

	return (
		<button disabled={props.executing} className="btn btn-primary">
			{props.executing && <span className="spinner-border spinner-border-sm mr-1"></span>}
			{t(props.label)}
		</button>);
}

function FormCancelButton(props: IFromCancelButtonProps) {
	const { t } = useTranslation();

	return (
		<button disabled={props.executing} className="btn btn-light" onClick={props.onClick}>
			{t(props.label || "common.cancel")}
		</button>);
}

function FormSection(props: React.PropsWithChildren<{ title: string | JSX.Element, fullWidth?: boolean | undefined }>) {
	const { t } = useTranslation();

	const title = typeof props.title === "string" ? t(props.title) : props.title;
	const css = {};
	if (props.fullWidth === true) {
		css["width"] = "100%";
	}

	return (
		<div className="card" style={ css }>
			<h4 className="card-header">{title}</h4>

			<div className="card-body">
				{props.children}
			</div>
		</div>
	);
}

function FormContainer(props: React.PropsWithChildren<{ title: string }>) {
	return (
		<div className="col-md-6 offset-md-3 mt-5">
			<FormSection {...props} />
		</div>
	);
}

function FormContainerPopin(props: React.PropsWithChildren<{ title: string }>) {
	const css = {
		"position": "fixed",
		"left": "0px",
		"top": "0px",
		"right": "0px",
		"bottom": "0px",
	} as CSSProperties

	const cssOverlay = {
		...css,
		"backgroundColor": "gray",
		"opacity": 0.5
	} as CSSProperties

	return (
		<div style={ css }>
			<div style={cssOverlay}></div>
			<div style={{ "opacity": 1}} className="col-md-6 offset-md-3 mt-5">
				<FormSection {...props} />
			</div>
		</div>
	);
}

export { FormInput, FormSubmitButton, FormCancelButton, FormSection, FormContainer, FormContainerPopin };