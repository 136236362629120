import React from "react";

export { ErrorMessage };

interface IErrorMessageProps {
    message: any
}

function ErrorMessage(props: React.PropsWithChildren<IErrorMessageProps>) {

    return <div className="alert alert-warning mt-3 mb-0">
        {props.message}
        {props.message && <br />}
        {props.children}
    </div>
}