import { Models, ChangeEvent, mui, config } from 'globalImport';
import { MoneyString } from '_components';

import { ICartItemOption } from '../cart/ICartItem';
import { IOptionGroupOption } from '../../_data';

function PriceLabel(props: { option: IOptionGroupOption }) {
	
	if (!config.showPrices) return <></>;

	const pDiff = props.option.priceDifference;
	const pNew = props.option.newBasePrice;
	const hasPDiff = !(pDiff === null || pDiff === undefined || pDiff.toString() === "0");
	const hasPNew = !(pNew === null || pNew === undefined || pNew.toString() === "0");

	if (!hasPDiff && !hasPNew) return <></>;

	let prefix = "";
	if (hasPDiff && pDiff > 0) prefix = "+";

	return (<>
		&nbsp;<small>({prefix}<MoneyString value={hasPDiff ? pDiff : pNew} />)</small>
	</>);
};

export function AddItemOption(props: {
	onChange: (cardItem: ICartItemOption) => any,
	selected: ICartItemOption | undefined,
	optionGroup: Models.IOptionGroup,
	allOptions: ICartItemOption[]
}) {
	const options = props.optionGroup.options;
	if (!options || options.length === 0) return <></>;
	
	const required = props.optionGroup.isRequired ? "*" : "";

	let value = "-1";
	if (props.selected && props.selected.option) value = props.selected.option.id;
	if (value === "-1" && required) value = props.optionGroup.options[0].id; // Gebeurd niet meer nu we default value instellen op parent component

	const onChangedSelect = (event: mui.SelectChangeEvent) => {
		const item = {
			optionGroup: props.optionGroup
		} as ICartItemOption;

		if (parseInt(event.target.value) > -1) {
			item.option = props.optionGroup.options.filter(o => o.id === event.target.value)[0];
		}

		props.onChange(item);
	}

	const onChangedSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		const item = {
			optionGroup: props.optionGroup
		} as ICartItemOption;

		if (event.target.checked) {
			item.option = props.optionGroup.options[0];
		}

		props.onChange(item);
	}

	
	if (props.optionGroup.dependecyOptionGroupId &&
		props.optionGroup.dependecyOptionGroupOptionId &&
		props.allOptions.filter(o =>
			o.optionGroup.id === props.optionGroup.dependecyOptionGroupId &&
			o.option?.id === props.optionGroup.dependecyOptionGroupOptionId).length === 0) {
		return <></>;
	}

	if (props.optionGroup.exceptOptionGroupId &&
		props.optionGroup.exceptOptionGroupOptionId &&
		props.allOptions.filter(o =>
			o.optionGroup.id === props.optionGroup.exceptOptionGroupId &&
			o.option?.id === props.optionGroup.exceptOptionGroupOptionId).length > 0) {
		return <></>;
	}

	return (
		<>
			

			{props.optionGroup.optionType === "One" &&
				props.optionGroup.options.length > 1 &&
				<>
					<mui.FormLabel>{props.optionGroup.name} {required}</mui.FormLabel>
					<mui.Select
						value={value}
						onChange={onChangedSelect}
						displayEmpty={true}
						fullWidth={true}>

						{!required && <mui.MenuItem value={-1}><em>&nbsp;</em></mui.MenuItem>}
						{props.optionGroup.options.map(o => <mui.MenuItem key={o.id} value={o.id}>
							{o.name}
							<PriceLabel option={o} />
						</mui.MenuItem>)};
					</mui.Select>
				</>
			}

			{props.optionGroup.optionType === "One" &&
				props.optionGroup.options.length === 1 &&
				<>
				<mui.FormLabel>{props.optionGroup.name} {required}</mui.FormLabel>
				<mui.Switch checked={value === props.optionGroup.options[0].id} onChange={onChangedSwitch} />
				{value === props.optionGroup.options[0].id && <span>
					{props.optionGroup.options[0].name}
					<PriceLabel option={props.optionGroup.options[0]} />
					</span>}

					<br />
				</>
			}
		</>);
}