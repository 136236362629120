import { yupResolver } from '@hookform/resolvers/yup';
import { Table } from 'react-bootstrap';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DateString, ErrorMessage, FormSection, MoneyString, ShowErrorMessage, ShowOnSuceeded } from '../_components';
import { IProduct, UoW } from './../_data/';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';


export function ProductEdit() {

	const navigate = useNavigate();

	const fillFields = (data: IProduct) => {
		for (let key in data) {
			setValue(key, data[key]);
		}
	}

	const id = useParams().id || 0;
	const getProductAction = UoW.Products.useGetById(id);
	const updateProductAction = UoW.Products.useUpdate(id);

	useEffect(() => {
		getProductAction.execute(null, fillFields);
	}, []);

	// form validation rules 
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required.')
	});
	const formOptions = { resolver: yupResolver(validationSchema) };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, formState, setValue } = useForm(formOptions);
	const { errors, isSubmitting } = formState;


	//if (!getProductAction.hasSuceeded) return <Busy />;
	if (getProductAction.hasError) return <ErrorMessage message={getProductAction.error} />;

	async function onSubmit(fields: any) {
		updateProductAction.execute({
			name: fields["name"],
			description: fields["description"],
			price: fields["price"],
			allowRemark: fields["allowRemark"],
			inWebshop: fields["inWebshop"],
		} as IProduct, getProductAction.execute);
	}

	return (
		<div className="editForm">
			<header>
				<ButtonGroup variant="outlined" aria-label="outlined primary button group">
					<Button
						onClick={handleSubmit(onSubmit)}
						disabled={isSubmitting}
					>

						{(updateProductAction.isLoading ||
							getProductAction.isLoading) &&
							<span className="spinner-border spinner-border-sm mr-1"></span>}
						Save
					</Button>

					<Button onClick={() => navigate('/shop/admin/products')}>To List</Button>
				</ButtonGroup>

				<ShowErrorMessage action={updateProductAction} />

				<ShowOnSuceeded action={updateProductAction}>
					<div className="alert alert-primary mt-3 mb-0">
						Product updated
					</div>
				</ShowOnSuceeded>
			</header>

			<div>
				<FormSection title={getProductAction.data?.name || ""}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-group">
							<label>Name</label>
							<input type="text" {...register('name')} name="name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
							<div className="invalid-feedback">{(errors as any).name?.message}</div>
						</div>

						<div className="form-group">
							<label>Category</label>
							<input disabled type="text" {...register('category')} name="category" className={`form-control ${errors.category ? 'is-invalid' : ''}`} />
							<div className="invalid-feedback">{(errors as any).category?.message}</div>
						</div>

						<div className="form-group">
							<label>Description</label>
							<input type="text" {...register('description')} name="description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} />
							<div className="invalid-feedback">{(errors as any).description?.message}</div>
						</div>

						<div className="form-group">
							<label>Price</label>
							<input type="text" {...register('price')} name="price" className={`form-control ${errors.price ? 'is-invalid' : ''}`} />
							<div className="invalid-feedback">{(errors as any).price?.message}</div>
						</div>


						<div className="form-group">
							<label>Allow Remark</label>
							<input type="checkbox" {...register('allowRemark')} name="allowRemark" className={`form-control ${errors.allowRemark ? 'is-invalid' : ''}`} />
							<div className="invalid-feedback">{(errors as any).allowRemark?.message}</div>
						</div>

						<div className="form-group">
							<label>Visible in webshop</label>
							<input type="checkbox" {...register('inWebshop')} name="inWebshop" className={`form-control ${errors.inWebshop ? 'is-invalid' : ''}`} />
							<div className="invalid-feedback">{(errors as any).inWebshop?.message}</div>
						</div>


					</form>
				</FormSection>

				<FormSection title="Prices">
					<Table striped bordered hover>
						<thead>
							<tr>
								<th style={{ display: 'none' }}>#</th>
								<th>Price</th>
								<th>Price From</th>
							</tr>
						</thead>
						<tbody>
							{getProductAction.data?.prices.map(p =>
								<tr key={p.id}>
									<td style={{ display: 'none' }}>{p.id}</td>
									<td><MoneyString value={p.price}/></td>
									<td><DateString value={p.activeFrom} /></td>
								</tr>)}
						</tbody>
					</Table>
				</FormSection>

				<FormSection title="Options">
					<Table striped bordered hover style={{ minWidth: "300px" }}>

						<tbody>
							{getProductAction.data?.options.map(p =>
								<>
									<tr key={p.id}>
										<td style={{ display: 'none' }}>{p.id}</td>
										<td>
											<Link to={"./../../productoptions/" + p.id.toString()}>{p.name}</Link></td>
										<td>{p.optionType}</td>
										<td><EditIcon
											style={{ cursor: "pointer" }}
											onClick={() => navigate("./../../productoptions/" + p.id.toString())} /></td>
									</tr>

									<tr key={p.id + "options"}>
										<td colSpan={3} >
											<div>{p.description}</div>

											<ul>
												{p.options.map(o => (
													<li>{o.name} {o.isDefault && (<small>(Default)</small>)}</li>
												))}
											</ul>
										</td>
									</tr>
								</>
							)}
						</tbody>
					</Table>
				</FormSection>
			</div>
		</div>
	);
}
