import { Route, Routes } from "globalImport";
import { Cart, FinalizeOrder } from "./cart";
import { Menu } from "./Menu";

export function ShopApp() {
	return (

		<Routes>
			<Route path="/" element={<div className="shopApp placeOrder">
				<Menu />

				<Cart />

			</div>} />

			<Route path="/FinalizeOrder" element={<FinalizeOrder />} />
		</Routes>
	);
}