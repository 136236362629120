import * as React from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import { history } from './_helpers';
import { NavBar, PrivateRoute } from './_components';
import { Home } from './home';
import { ForgotPassword, Login, ResetPassword } from './login';
import * as Admin from './Admin';
import { Register } from './login/Register';
import { VerifyEmail } from './login/VerifyEmail';
import { OrderDetails, Orders } from './orders';

export { App };

function App() {
	// init custom history object to allow navigation from 
	// anywhere in the react app (inside or outside components)
	history.navigate = useNavigate();
	history.location = useLocation();

	return (
		<Suspense fallback="...">
			<div id="reactRoot" className="app-container bg-light">
				<header style={{ position: "relative", zIndex: 10000 }}>
					<NavBar />
				</header>
				<div className="container pt-4 pb-4 bg-white">
					<Routes>
						<Route path="/shop/admin/accounts" element={<PrivateRoute><Admin.Accounts /></PrivateRoute>} />
						<Route path="/shop/admin/products" element={<PrivateRoute><Admin.Products /></PrivateRoute>} />
						<Route path="/shop/admin/products/:id" element={<PrivateRoute><Admin.ProductEdit /></PrivateRoute>} />

						<Route path="/shop/admin/productoptions" element={<PrivateRoute><Admin.OptionGroups /></PrivateRoute>} />
						<Route path="/shop/admin/productoptions/:id" element={<PrivateRoute><Admin.OptionGroupEdit /></PrivateRoute>} />

						<Route path="/shop/login" element={<Login />} />
						<Route path="/shop/login/ForgotPassword" element={<ForgotPassword />} />
						<Route path="/shop/login/reset" element={<ResetPassword />} />
						<Route path="/shop/register" element={<Register />} />
						<Route path="/shop/verify-email" element={<VerifyEmail />} />

						<Route path="/shop/orders" element={<Orders />} />
						<Route path="/shop/orders/:id" element={<OrderDetails />} />

						<Route path="/shop/*" element={<Home />} />

						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</div>
			</div>
		</Suspense>
	);
}

