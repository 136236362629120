import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authActions, IRootStore, IUser } from "../_store";

export { LoginButton };
function LoginButton() {
	const { t } = useTranslation();
	const authUser = useSelector<IRootStore, IUser | null>((x) => x.auth!.user);
	const dispatch = useDispatch();
	const login = () => dispatch(authActions.logout());

	return	<>
		{!authUser &&
			<Button
				onClick={login}
				variant="contained">{t('nav.login')}</Button>
		}
	</>
}