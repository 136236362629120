import { useTranslation, redux, useUser, useNavigate, useCart } from 'globalImport';

import { Icons, ShowIf, ShowIfAdmin, ShowIfAuthenticated, Trans } from '_components';
import { Button, Container, Form, Nav, NavDropdown, Navbar } from 'react-bootstrap';


export function NavBar() {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const authUser = useUser();
	const dispatch = redux.useDispatch();
	const { totalItems } = useCart();
	const logout = () => dispatch(redux.authActions.logout());
	const login = () => dispatch(redux.authActions.logout());

	return (
		<Navbar collapseOnSelect expand="sm" className="bg-body-tertiary navbar-dark bg-dark">
			<Container fluid>
				<Navbar.Brand href="#" onClick={() => window.location.href = "/"}>'t Smosken</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbarScroll" />
				<Navbar.Collapse id="navbarScroll" className="justify-content-between">
					<Nav
						className="me-auto my-2 my-lg-0"
						style={{ maxHeight: '100px' }}
						navbarScroll
					>
						<Nav.Link eventKey="1" onClick={() => navigate("/shop/")} >Home</Nav.Link>

						<ShowIfAuthenticated>
							<Nav.Link eventKey="orders-1" onClick={() => navigate('/shop/orders')}>{t("orders.name")}</Nav.Link>
						</ShowIfAuthenticated>

						<ShowIfAdmin>
							<NavDropdown title="Admin Center" id="basic-nav-dropdown" >
								
									<Nav.Link eventKey="admin-1" onClick={() => navigate('/shop/admin/accounts')}>Accounts</Nav.Link>
								
								<NavDropdown.Divider />

								
									<Nav.Link eventKey="admin-2" onClick={() => navigate('/shop/admin/products')}>Edit Products</Nav.Link>
								

								
									<Nav.Link eventKey="admin-3" onClick={() => navigate('/shop/admin/productoptions')}>Edit Product options</Nav.Link>
								

							</NavDropdown>
						</ShowIfAdmin>

					</Nav>
					<NavDropdown.Divider />
					<Nav
						className="me-auto my-2 my-lg-0"
						style={{ maxHeight: '100px' }}
						navbarScroll
					>

						{totalItems > 0 && <Nav.Link eventKey="FinalizeOrder-1" onClick={() => navigate("/shop/FinalizeOrder/")}><Icons.ShoppingCart />({totalItems })</Nav.Link>}

						{i18n.resolvedLanguage === 'en' && <Nav.Link eventKey="101" onClick={() => i18n.changeLanguage('nl')}>NL</Nav.Link>}
						{i18n.resolvedLanguage === 'nl' && <Nav.Link eventKey="102" onClick={() => i18n.changeLanguage('en')}>EN</Nav.Link>}

						{!authUser && <Nav.Link eventKey="103" onClick={() => navigate('/shop/register')}>{t('login.register.registerButton')}</Nav.Link>}
						{authUser && <Nav.Link eventKey="104" onClick={logout}><Trans value='nav.logout' /></Nav.Link>}
						{!authUser && <Nav.Link eventKey="105" onClick={login}><Trans value='nav.login' /></Nav.Link>}

					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>


	);
}

