import { config } from '.';
import { store, authActions } from '../_store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method: string) {
    return (url, body?) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        } as RequestInit;

        if (body) {
            requestOptions.headers!['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        
        return fetch(url, requestOptions)
            .then(handleResponse);
    }
}

// helper functions

function authHeader(url): { [key: string]: string } {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(config.baseUrl);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${token}` };
    } 
    return {};
}

function authToken() {
    return store.getState().auth.user?.jwtToken;
}

function handleResponse(response: Response) {
    if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
        } else {
            return response.text();
        }
    }

    return response.text().then(text => {
        let data: any = text /*&& JSON.parse(text)*/;

		try {
            data = text && JSON.parse(text);
		} catch (e) {}

        if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken()) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                const logout = () => store.dispatch(authActions.logout());
                logout();
            }

            if (typeof data === 'string' || data instanceof String) {
                return Promise.reject(response.statusText + ": " +data);
            }

            let error = response.statusText;
            if (data && data.errors) {
                error = "";
                for (let key in data.errors) {
                    error += key + ": " + data.errors[key] + "\r\n";
                }
                error = error.trim();
            }
            if (data && data.message) error = data.message;

            return Promise.reject(error);
        }

        return data;
    });


}

/*

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken()) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                const logout = () => store.dispatch(authActions.logout());
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
 */