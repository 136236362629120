import { UoW, Data, Link, useAuthUser, useEffect, Helpers } from 'globalImport';

import { FieldValues, useForm } from "react-hook-form";

import { FormContainer, FormInput, FormSubmitButton, ShowErrorMessage, ShowSuceededMessage } from '../_components';
import { YupValidationBuilder } from './Helpers/YupValidationBuilder';

function ResetPassword() {
	const validateResetTokenAction = UoW.Users.useValidateResetToken();
	const resetTokenAction = UoW.Users.useResetToken();

	const auth = useAuthUser();
	const authUser = auth?.user;

	useEffect(() => {
		// redirect to home if already logged in
		if (authUser) Helpers.history.navigate!('/shop');
	}, [authUser]);

	const formOptions = { resolver: YupValidationBuilder("password", "confirmPassword") };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, formState } = useForm(formOptions);
	const { errors } = formState;


	const token = new URLSearchParams(window.location.search).get("token");
	useEffect(() => {
		if (token && !validateResetTokenAction.hasStarted) validateResetTokenAction.execute(token);
	}, [token, validateResetTokenAction]);

	async function onSubmit(fields: FieldValues) {
		resetTokenAction.execute({
			password: fields["password"],
			confirmPassword: fields["confirmPassword"],
			token: token
		} as Data.User.IResetTokenRequest);
	}

	if (validateResetTokenAction.isLoading) return (<div className="d-flex justify-content-center"><div className="spinner-border" role="status"></div></div>);

	if (!token ||
		validateResetTokenAction.hasError ||
		(validateResetTokenAction.hasSuceeded && validateResetTokenAction.data?.message != "Token is valid")) {

		return (
			<FormContainer title="Reset password">
				<div className="alert alert-danger mt-3 mb-0">
					Geen geldige URL.
					<br />
					<small>
						(
						{validateResetTokenAction.data?.message && validateResetTokenAction.data?.message}
						{validateResetTokenAction.hasError && validateResetTokenAction.error}
						)</small>
					<br />
					<Link to="/shop/login/ForgotPassword">Opnieuw proberen</Link>
				</div>
			</FormContainer>);
	}

	return (
		<FormContainer title="Reset password">
			<form onSubmit={handleSubmit(onSubmit)}>
				{!resetTokenAction.hasSuceeded &&
					<>

						<FormInput label="login.newPassword" type="password" name="password" register={register} errors={errors} />

						<FormInput label="login.passwordConfirm" type="password" name="confirmPassword" register={register} errors={errors} />

						<FormSubmitButton
							label="login.register.registerButton"
							executing={resetTokenAction.isLoading} />

					</>
				}

				<ShowErrorMessage action={resetTokenAction} />
				<ShowSuceededMessage action={resetTokenAction}>
					<br />
					<Link className="btn btn-primary" to="/shop/login" relative="path">Inloggen</Link>
				</ShowSuceededMessage>
			</form>
		</FormContainer>);
}

export { ResetPassword };