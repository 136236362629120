import { UoW, Data, useCart, useTranslation, useNavigate, mui, useUser, useState } from "globalImport";
import { Line, LoginButton, HideIfCompany, Trans, MoneyString, ShowErrorMessage, ShowIfCompany, ShowIfNotCompany, ShowIf } from "_components";

import { ICartItem } from "./ICartItem";
import { CartItem } from "./cartItem";
import { OrderForDeliveryTimeVisible, OrderTimeVisible, OrderTimeWarning } from "./TimeWarning";
import { DeliveryAddressForm, IDeliveryAddressForm } from "./DeliveryAddressForm";
import { ICreateOrderRequest, IOrderDelivery } from "../../_data/Models/Order";
import { Table } from "react-bootstrap";
import { OrderDetailLines } from "../../orders/OrderDetailLines";


function RadioButton({ label, pickup, onChange }) {
	return (<mui.FormControlLabel
		value="top"
		control={
			<mui.Radio
				checked={pickup}
				onChange={onChange}
				value="a"
				name="radio-buttons"
				inputProps={{ 'aria-label': label }}
			/>}
		label={label}
		labelPlacement="end"
	/>);
}

export function FinalizeOrder() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const authUser = useUser();

	const [pickup, setPickup] = useState(true);//useState(!authUser?.company);
	const [paymentMethod, setPaymentMethod] = useState<"Cash" | "Payconiq" | "Invoice">(authUser?.company ? "Invoice" : "Cash");
	const [formData, setFormData] = useState({} as IDeliveryAddressForm);

	const createOrderAction = UoW.Orders.useCreate();
	const {
		isEmpty,
		totalItems,
		totalUniqueItems,
		items,
		updateItemQuantity,
		removeItem,
		emptyCart
	} = useCart();
	const cartItems = items as Array<ICartItem>;

	if (isEmpty) navigate(-1);

	const submitCart = () => {
		const orderDelivery = {
			isPickup: pickup

		} as IOrderDelivery

		if (!pickup) {
			orderDelivery.city = formData.city;
			orderDelivery.companyName = formData.bedrijfsNaam;
			orderDelivery.street = formData.street + " " + formData.streetNumber;
		}

		const data = {
			items,
			isEmpty,
			totalItems,
			totalUniqueItems,
			orderDelivery,
			paymentMethod
		} as ICreateOrderRequest;

		createOrderAction.execute(data, submitCartCompleted);
	}

	const submitCartCompleted = (response: Data.Order.IOrderCreateResponse) => {
		emptyCart();
		if (response.paymentMethod === "Payconiq" && response.paymentUrl) {
			window.location.href = response.paymentUrl;
			return;
		}
		navigate('/shop/orders/' + response.orderId, { state: { orderCompleted: true } });
	}

	let submitDisbaled = createOrderAction.isLoading || (!pickup && !formData.isComplete);


	return (
		<div className="shopApp finalizeOrder">
			<h2>Overzicht</h2>
			<Line />


			{createOrderAction.hasError &&
				<div className="alert alert-danger mt-3 mb-0">
					{t(createOrderAction.error || "")}
				</div>
			}

			{createOrderAction.hasSuceeded &&
				totalUniqueItems === 0 &&
				<div className="alert alert-info mt-3 mb-0">
					<Trans value={createOrderAction.data?.message} />
				</div>
			}

			<div className="itemsContainter resize">
				<OrderDetailLines lines={cartItems} showHeader showLines />
			</div>

			<div className="itemsContainter" style={{ overflow: "hidden" }}>
				<OrderDetailLines lines={cartItems} showTotal />
			</div>

			<mui.Grid container spacing={2} style={{ marginBottom: "15px", display: "none" }}>
				<mui.Grid item xs={12} md={6} style={{ textAlign: "center" }}>
					<RadioButton
						label="Ophaling"
						pickup={pickup}
						onChange={() => setPickup(true)} />
						<br />
					<small>Levering niet meer mogelijk.</small>
					{false && 
					<RadioButton
						label="Levering"
						pickup={!pickup}
							onChange={() => setPickup(false)} />}
				</mui.Grid>
				

			</mui.Grid>

			<mui.Grid container >
				


				{pickup &&
					<mui.Grid item xs={12} style={{ textAlign: "center" }}>
						Uw bestelling zal klaar liggen om 11:30 of 30 min na uw bestelling indien besteld na 11 uur.<br />

						Nieuwstraat 31 - Lede
					</mui.Grid>
				}

				<mui.Grid item xs={12} style={{ textAlign: "center", display: (pickup ? "none" : "block") }}>
					Wij brengen uw bestelling voor 12:00 tot bij u.

					<HideIfCompany>
						<br /><br />
						Dagelijkse leveringen zijn niet meer mogelijk
						<br />
						Uitzonderlijke leveringen kunnen aangevraagd worden. Stuur uw aanvraag naar
						&nbsp;<a href="mailto:info@smosken.be">info@smosken.be</a>
						<br />
					</HideIfCompany>

					<br /><br />

					<DeliveryAddressForm onChange={setFormData} />

					<HideIfCompany>
						<br /><br />
						Betaling gebeurt bij levering. Cash (gepast) of via Payconiq.
						<br />
						Kan ook op factuur contacteer ons voor meer info.
						&nbsp;<a href="mailto:info@smosken.be">info@smosken.be</a>
						<br />
					</HideIfCompany>
				</mui.Grid>

				<mui.Grid item xs={12}>
					<Line />
				</mui.Grid>

				<mui.Grid item xs={12}>
					<OrderTimeWarning hideWhenOnTime />
				</mui.Grid>

				<mui.Grid item xs={4}>
					<mui.Button onClick={() => navigate(-1)}>Back</mui.Button>
				</mui.Grid>

				<mui.Grid item xs={8} style={{ textAlign: "end", marginBottom: "10px" }}>

					<ShowIfNotCompany>
						<mui.Select
							autoWidth
							labelId="paymentType"
							label="Betaling"
							value={paymentMethod}
							onChange={(e) => setPaymentMethod(e.target.value as any)}

							inputProps={{ 'aria-label': 'Without label' }}
						>
							<mui.MenuItem value={"Cash"}>Betaling bij Ophaling</mui.MenuItem>
							<mui.MenuItem value={"Payconiq"}>Betaling via Payconiq</mui.MenuItem>
							
						</mui.Select>
					</ShowIfNotCompany>


					<ShowIfCompany>
						<mui.Select
							autoWidth
							labelId="paymentType"
							label="Betaling"
							value={paymentMethod}
							onChange={(e) => setPaymentMethod(e.target.value as any)}

							inputProps={{ 'aria-label': 'Without label' }}
						>
							<mui.MenuItem value={"Payconiq"}>Betaling via Payconiq</mui.MenuItem>
							<mui.MenuItem value={"Cash"}>Betaling bij Levering</mui.MenuItem>
							<mui.MenuItem value={"Invoice"}>Betaling via Invoice</mui.MenuItem>
						</mui.Select>
					</ShowIfCompany>

				</mui.Grid>


				<mui.Grid item xs={12} style={{ textAlign: "end" }}>

					<ShowIf condition={!!authUser && totalUniqueItems > 0}>

						<ShowIf condition={!pickup}>
							<OrderForDeliveryTimeVisible>
								<mui.Button
									disabled={submitDisbaled}
									variant="contained"
									onClick={submitCart}>
									{createOrderAction.isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
									{t('shop.cart.order')}</mui.Button>
							</OrderForDeliveryTimeVisible>
						</ShowIf>

						<ShowIf condition={pickup}>
							<OrderTimeVisible>
								<mui.Button
									disabled={submitDisbaled}
									variant="contained"
									onClick={submitCart}>
									{createOrderAction.isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
									{t('shop.cart.order')}</mui.Button>
							</OrderTimeVisible>
						</ShowIf>

					</ShowIf>


					<LoginButton />
				</mui.Grid>
			</mui.Grid>

		</div>
	);
}