import { Link } from 'react-router-dom';
import { ShopApp } from '../shop';
import { ShowIfAdmin } from '_components';

export function Home() {

	return (
		<div className="homePage">

			<ShowIfAdmin>
				
					<div className="alert alert-warning alert-dismissible fade show" role="alert">
						<h5 className="alert-heading">Interne mededeling</h5>
						<hr />
						<p className="mb-0">09/10/2023: Vanaf nu kan je zelf opties op producten beheren.</p>
						<p className="mb-0">16/10/2023: Bij bestellen kan men kiezen tussen ophalen of levering.</p>
						<p className="mb-0">18/10/2023: Admins kunnen nu altijd bestellen.</p>
						<p className="mb-0">29/10/2023:&nbsp;
							<Link to="/shop/orders">History van bestellingen.</Link>
						</p>
						<p className="mb-0">04/11/2023: Product prijzen.</p>
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				
			</ShowIfAdmin>

			<div className="body">
				<ShopApp />
			</div>
		</div>
	);
}
