import { useEffect, useState } from "react"
import { fetchWrapper, config } from "../_helpers";

export interface IRepository<T> {
	useGet: () => IApiData<Array<T>>;
	useGetById: (id: number | string) => IApiData<T>;
	useUpdate: (id: number | string) => IApiData<any>;
	useCreate: (id: number | string) => IApiData<any>;
}

export interface IApiData<T, I = any> {
	execute: (postData?: I, callback?: (data: T) => any) => any;
	reset: () => any;
	data: T | null;
	error: string | null;
	isLoading: boolean;
	hasSuceeded: boolean;
	hasError: boolean;
	hasStarted: boolean;
}



export class BaseRepository<T> implements IRepository<T>
{
	constructor(public endpoint: string) { }

	useGet = () => this.useApiAction<Array<T>>(this.endpoint);
	useGetById = (id: number | string) => this.useApiAction<T>(this.endpoint + "/" + id.toString());

	useCreate = () => {
		return this.useApiAction<any, any>(this.endpoint + "/create", "POST");
	}

	useUpdate = (id: number | string) => {
		return this.useApiAction<any, T>(this.endpoint + "/" + id, "POST");
	}


	protected useApiAction = function useApiAction<T, I = any>(
		endpoint: string | ((data: I) => string),
		methode: "GET" | "POST" | "PUT" |"DELETE" = "GET",
		postDataTransformer?: (data: I) => any): IApiData<T, I>
	{
		const [executeTrigger, setExecuteTrigger] = useState(1);
		const [callback, setCallback] = useState<(any) | null>(null);

		const defaultValues = {
			data: null,
			error: null,
			hasError: false,
			hasSuceeded: false,
			isLoading: false,
			hasStarted: false
		} as IApiData<T, I>

		const [theState, setTheState] = useState<IApiData<T, I>>(defaultValues);

		const [postData, setPostData] = useState<any | null>(null);
		const [rawPostData, setRawPostData] = useState<any | null>(null);

		useEffect(() => {
			if (executeTrigger > 1) {

				setTheState({
					data: null,
					error: null,
					hasError: false,
					hasSuceeded: false,
					isLoading: true,
					hasStarted: true
				} as IApiData<T, I>);

				let action = fetchWrapper.get;
				if (methode === "POST") action = fetchWrapper.post;
				if (methode === "PUT") action = fetchWrapper.put;
				if (methode === "DELETE") action = fetchWrapper.delete;
				const body = methode == "GET" ? null : postData;

				let buildEndpoint = config.baseUrl;
				if (typeof endpoint === 'string' || endpoint instanceof String) {
					buildEndpoint += '/' + endpoint;
				}
				else
				{
					buildEndpoint += '/' + endpoint(rawPostData);
				}

				action(buildEndpoint, body)
					.then(r => {
						setTheState({
							data: r,
							error: null,
							hasError: false,
							hasSuceeded: true,
							isLoading: false,
							hasStarted: true
						} as IApiData<T, I>);

						if (callback) setTimeout(callback.action(r),100);
					})
					.catch(r => {

						setTheState({
							data: null,
							error: r,
							hasError: true,
							hasSuceeded: false,
							isLoading: false,
							hasStarted: true
						} as IApiData<T, I>);
					});
			}
		}, [executeTrigger]);

		const execute = (postData?: I, callbackArgument?: (data: T) => any) => {
			setRawPostData(postData);
			if (postData && postDataTransformer) {
				setPostData(postDataTransformer(postData));
			}
			else {
				setPostData(postData || null);
			}

			if (callbackArgument) {
				// work around because it wont work when passing a function
				const action = { action: (r) => callbackArgument(r) };
				setCallback(action);
			}
			setExecuteTrigger(executeTrigger + 1);
		}

		const reset = () => {
			setTheState(defaultValues);
			setPostData(null);
			setRawPostData(null);
		}

		return {
			...theState,
			execute,
			reset
		} as IApiData<T, I>
	}
}

