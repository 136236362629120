import React, { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Busy, ErrorMessage, MoneyString, Search } from '../_components';
import { UoW } from './../_data/';
import { useTranslation } from 'react-i18next';
import { ProductTable } from './_components/ProductTable';

export function Products() {
	const { t } = useTranslation();
	const getProductAction = UoW.Products.useGet();

	useEffect(() => {
		getProductAction.execute();
	}, []);

	if (!getProductAction.hasSuceeded) return <Busy />;
	if (getProductAction.hasError) return <ErrorMessage message={getProductAction.error} />;

	return (
		<div style={{ width: "100%" }}>
			<ProductTable data={getProductAction.data} />
		</div>
	);
}
