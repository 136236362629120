import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { IUser } from './IRootStore';

export * from './auth.slice';
export * from './IRootStore';

export const currentUser = () => localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) as IUser : null;

export const store = configureStore({
    reducer: {
        auth: authReducer,
    },
});