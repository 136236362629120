import { UoW, useCart, mui, useTranslation, config, useAuthUser, useNavigate } from "globalImport";
import { Line, LoginButton, MoneyString, Icons } from "_components";

import { ICartItem } from "./ICartItem";
import { OrderTimeVisible, OrderTimeWarning } from "./TimeWarning";
import { CartItem } from "./cartItem";

export { FinalizeOrder } from './FinalizeOrder';

export function Cart() {
	const { t } = useTranslation();
	const authUser = useAuthUser();
	const navigate = useNavigate();

	const {
		isEmpty,
		totalItems,
		totalUniqueItems,
		items,
		updateItemQuantity,
		removeItem,
		emptyCart
	} = useCart();
	const cartItems = items as Array<ICartItem>;

	if (isEmpty) return <mui.Grid item container xs={12}
		className="cart empty">{t("shop.cart.empty")}</mui.Grid>;

	const renderButtom = (action, icon) => {
		return (
			<mui.Grid item xs={4} style={{ textAlign: "center" }}>
				<mui.Fab
					size="small" color="primary"
					onClick={action}>
					{icon}
				</mui.Fab>
			</mui.Grid>);
	}

	const sum = function (items: Array<ICartItem>) {
		return items.reduce(function (a, b) {
			return a + (b.price * (b.quantity || 0));
		}, 0);
	};


	return (
		<mui.Grid item container xs={12}
			className="cart">
			<mui.Grid container flexDirection="column"
				spacing={3}>
				<mui.Grid item>
					<h3>{t('shop.cart.name')} ({totalUniqueItems})</h3>
					<OrderTimeWarning />



				</mui.Grid>
				<mui.Grid item>


					{totalUniqueItems > 0 &&
						<>
							{config.showPrices &&
								<>
							<span>Totaal <MoneyString value={sum(cartItems)} /></span>
									<br />
								</>}
							<mui.Button
								variant="contained"
								onClick={() => navigate("/shop/FinalizeOrder")}>
								{t('shop.cart.order')}</mui.Button>
						</>}


				</mui.Grid>
				<Line />
				<mui.Grid item>
					<mui.Stack spacing={3}>

						{cartItems.map((item: ICartItem) => (
							<mui.Grid container key={item.id}>

								<mui.Grid item xs={12}>
									<CartItem item={item} />
								</mui.Grid>

								<mui.Grid item container xs={12}>
									{renderButtom(
										() => updateItemQuantity(item.id, item.quantity! + 1),
										<Icons.Add />
									)}

									{renderButtom(
										() => updateItemQuantity(item.id, item.quantity! - 1),
										<Icons.Remove />
									)}

									{renderButtom(
										() => removeItem(item.id),
										<Icons.Delete />
									)}
								</mui.Grid>

								<mui.Grid item xs={12}
									style={{
										backgroundColor: "lightGrey",
										height: "1px",
										marginTop: "15px",
										marginBottom: "25px"
									}}>

								</mui.Grid>
							</mui.Grid>
						))}
					</mui.Stack>
				</mui.Grid>

			</mui.Grid>


		</mui.Grid>
	);
}

