import React, { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Busy, ErrorMessage, Search } from '../../_components';
import { UoW } from './../../_data/';
import { useTranslation } from 'react-i18next';

export function OptionGroups() {
	const { t } = useTranslation();
	const getDataAction = UoW.GroupOptions.useGet();
	const [searchTerm, setSearchTerm] = React.useState("");

	useEffect(() => {
		getDataAction.execute();
	}, []);


	if (!getDataAction.hasSuceeded) return <Busy />;
	if (getDataAction.hasError) return <ErrorMessage message={getDataAction.error} />;

	let data = getDataAction.data ?? [];
	if (searchTerm) data = data.filter(p =>
		p.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
		(p.description && p.description.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
	);

	return (
		<div style={{ width: "100%" }}>
			<Search value={searchTerm} onChange={setSearchTerm} />

			<Table striped bordered hover>
				<thead>
					<tr>
						<th style={{ display: "none" }}>#</th>
						<th>Name</th>
						<th>Description</th>
						<th>Type</th>
						<th>Is Required</th>
					</tr>
				</thead>
				<tbody>
					{data?.map(o =>
						<tr key={o.id}>
							<td style={{ display: "none" }}>{o.id}</td>
							<td><Link to={o.id.toString()}>{o.name}</Link></td>
							<td>{o.description}</td>
							<td>{o.optionType}</td>
							<td>{o.isRequired && <CheckIcon />}</td>
							
						</tr>
					)}
				</tbody>
			</Table>

		</div>
	);
}
