import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Busy, ErrorMessage, FormContainerPopin, FormSection, MoneyString, ShowErrorMessage, ShowIf, ShowOnSuceeded } from '../../_components';
import { IOptionGroupOption, UoW } from './../../_data/';
import { useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { Button, ButtonGroup, Link } from '@mui/material';
import React from 'react';
import { OptionGroupOptionEdit, OptionGroupOptionEditResult } from './OptionGroupOptionEdit';
import { ProductTable } from '../_components/ProductTable';

function Products(props: {optionGroupId: string}) {

	const getProductAction = UoW.GroupOptions.useGetProducts(props.optionGroupId);

	useEffect(() => {
		getProductAction.execute();
	}, []);

	if (!getProductAction.hasSuceeded) return <Busy />;
	if (getProductAction.hasError) return <ErrorMessage message={getProductAction.error} />;

	return (
		<div style={{ width: "100%" }}>
			<ProductTable data={getProductAction.data} />
		</div>
	);
}

export function OptionGroupEdit() {

	const navigate = useNavigate();

	const [currentEditOption, setCurrentEditOption] = React.useState(null as null | IOptionGroupOption);

	const id = useParams().id || "0";
	const getRecordAction = UoW.GroupOptions.useGetById(id);
	const addOptionAction = UoW.GroupOptions.useCreateOption();
	const updateOption = UoW.GroupOptions.useUpdateOption();
	const deleteOptionAction = UoW.GroupOptions.useDeleteOption();

	useEffect(() => { getRecordAction.execute(); }, []);

	const onUupsertedOption = (result: OptionGroupOptionEditResult) => {
		if (result.action === "new") {
			getRecordAction.data?.options.push(result.option!);
		}
		if (result.action === "updated") {
			const objIndex = getRecordAction.data!.options.findIndex((o => o.id === result.option?.id));
			getRecordAction.data!.options[objIndex] = result.option!;
		}
		setCurrentEditOption(null);
	}

	const editOption = (option: IOptionGroupOption) => {
		setCurrentEditOption(option);
	}

	const deleteOption = (optionId) => {
		addOptionAction.reset();

		deleteOptionAction.execute(
			{ groupId: id, optionId },
			() => {
				if (!getRecordAction.data) return;
				getRecordAction.data.options = getRecordAction.data.options.filter(o => o.id != optionId);
			}
		);
	}

	if (!getRecordAction.hasSuceeded) return <Busy />;
	if (getRecordAction.hasError) return <ErrorMessage message={getRecordAction.error} />;
	if (!getRecordAction.data) return <ErrorMessage message="Data failed to load" />;

	const d = getRecordAction.data;

	return (
		<div className="editForm">
			<header>
				<ButtonGroup variant="outlined" aria-label="outlined primary button group">
					<Button onClick={() => navigate(-1)}>Back</Button>
				</ButtonGroup>

				<ShowErrorMessage action={deleteOptionAction} />

				<ShowOnSuceeded action={deleteOptionAction}>
					<div className="alert alert-primary mt-3 mb-0">
						Option deleted
					</div>
				</ShowOnSuceeded>

			</header>
			<div>
				<FormSection title={d.name || ""}>
					<div>Description: {d.description}</div>
					<div>Rype: {d.optionType}</div>
					<div>Is Required: {d.isRequired}</div>
					<br />
					<ShowIf condition={!!d.dependecyOptionGroupId}>
						<div>Only show when group: {d.dependecyOptionGroupId} has option {d.dependecyOptionGroupOptionId}</div>
					</ShowIf>

					<ShowIf condition={!!d.exceptOptionGroupId}>
						<div>Hide when group: {d.exceptOptionGroupId} has option {d.exceptOptionGroupOptionId}</div>
					</ShowIf>
					
				</FormSection>

				<FormSection title="Options">
					<div>
						<OptionGroupOptionEdit
							option={currentEditOption}
							groupId={id}
							onClose={onUupsertedOption}
							action={currentEditOption ? updateOption : addOptionAction} />
					</div>

					<Table striped bordered hover>
						<thead>
							<tr>
								<th style={{ display: 'none' }}>#</th>
								<th>Name</th>
								<th>Price difference</th>
								<th>New Base Price</th>
								<th>Is Default</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{d.options.map(p =>
								<tr key={p.id}>
									<td style={{ display: 'none' }}>{p.id}</td>
									<td>{p.name}</td>
									<td><MoneyString value={p.priceDifference} /></td>
									<td><MoneyString value={p.newBasePrice} /></td>
									<td>{p.isDefault && <CheckIcon />}</td>
									<td><EditIcon
										style={{ cursor: "pointer" }}
										onClick={(e) => editOption(p)} /></td>
									<td><DeleteIcon
										style={{ cursor: "pointer" }}
										onClick={(e) => deleteOption(p.id)} /></td>
								</tr>)}
						</tbody>
					</Table>
				</FormSection>

			</div>

			<div>
				<FormSection title="Products" fullWidth >
					<Products optionGroupId={id} />
				</FormSection>
			</div>
		</div>
	);
}
