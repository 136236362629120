import { UoW, useTranslation, useState, useEffect, mui, useLocation } from 'globalImport';

import { Search, Icons } from '_components';

import { MenuItem } from "./MenuItem";
import { Table } from "react-bootstrap";

export function Menu() {
    const { t } = useTranslation();

    const productsLoader = UoW.Products.useGet();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        productsLoader.execute();
    }, []);

    let products = productsLoader.data?.filter(p => p.inWebshop);
    if (searchTerm) products = products?.filter(p =>
        p.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        (p.description && p.description.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
    );

    const catergories = products?.map(p => p.category)
        .filter((value, index, array) => array.indexOf(value) === index); // Get distinct

    return (
        <mui.Grid className="menu" item xs={12}>

            <div>Welkom op de webshop van 't Smosken.
                <br />
                Hier kan je broodjes, slaatjes,... bestellen voor afhaling.
                < br />
                
            </div>

            <Search value={searchTerm} onChange={setSearchTerm} />

            {productsLoader.isLoading && <mui.Stack><div className="spinner-border spinner-border-sm"></div></mui.Stack>}
            {productsLoader.hasError && <div className="text-danger">{t('common.errorLoadingData')}: {productsLoader.error?.toString()}</div>}
            {productsLoader.hasSuceeded &&
                <mui.Stack spacing={2}>
                    {catergories?.map((c, i) =>
                        <mui.Accordion key={c} defaultExpanded={i === 0 ||true}>
                            <mui.AccordionSummary
                                expandIcon={<Icons.ExpandMore />}
                                aria-controls={c}
                                id={c}>
                                <h3>{c}</h3>
                            </mui.AccordionSummary>
                            <mui.AccordionDetails>
                                <Table hover className="productTable">
                                    <tbody>
                                        {products?.filter(v => v.category === c).map(p =>
                                            <MenuItem key={p.id} product={p} />
                                        )}        
                                    </tbody>
                                </Table>
                            </mui.AccordionDetails>
                        </mui.Accordion>

                    )}
                </mui.Stack>
            }
        </mui.Grid>
    );
}