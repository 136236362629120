import { useSelector, useDispatch } from "react-redux";
import { IAuth, IRootStore, IUser } from "./_store/IRootStore";
import { authActions } from './_store';

export { useEffect, useRef, useState } from 'react';
export type { ChangeEvent } from 'react';

export { Route, Routes, Link } from "react-router-dom";
export { useNavigate, useLocation } from "react-router-dom";

export const redux = { useSelector, useDispatch, authActions };
export * as reduxStore from "_store";
export { nanoid } from '@reduxjs/toolkit';

export * as mui from "@mui/material";

export { useTranslation } from "react-i18next";

export { CartProvider, useCart } from "react-use-cart";
export * as CartModels from "react-use-cart";

export { UoW } from "./_data";
export * as Data from "./_data";
export * as Models from "./_data/Models";

export { config } from "_helpers";
export * as Helpers from '_helpers';

// todo refactor this
export const useUser = () => useSelector<IRootStore, IUser | null>((x) => x.auth!.user);
export const useAuthUser = () => useSelector<IRootStore, IAuth | null>(x => x.auth);
