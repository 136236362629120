import { UoW, Link, useTranslation, useAuthUser, useEffect, useRef } from 'globalImport';

import { history } from '../_helpers';
import { FormContainer, LoginButton } from '../_components';

function VerifyEmail() {
	const { t } = useTranslation();
	const resetTokenAction = UoW.Users.useVerifyEmail();
	const requestStartedRef = useRef(false);

	const auth = useAuthUser();
	const authUser = auth?.user;

	useEffect(() => {
		// redirect to home if already logged in
		if (authUser) history.navigate!('/shop');
	}, []);

	const token = new URLSearchParams(window.location.search).get("token");
	useEffect(() => {
		if (token && !requestStartedRef.current) {
			requestStartedRef.current = true;
			resetTokenAction.execute({ token: token });
		}
	});

	if (resetTokenAction.isLoading) return (<div className="d-flex justify-content-center"><div className="spinner-border" role="status"></div></div>);

	if (!token ||
		resetTokenAction.hasError ||
		(resetTokenAction.hasSuceeded && resetTokenAction.data?.message != "login.emailVerificationSuccessfull")) {

		return (
			<FormContainer title="Voltooi Registratie">
				<div className="alert alert-danger mt-3 mb-0">
					Geen geldige URL.
					<br />
					<small>
						{resetTokenAction.data?.message && t(resetTokenAction.data?.message)}
						{resetTokenAction.hasError && t(resetTokenAction.error || "")}
					</small>
					<br />
					<Link to="/shop/login/ForgotPassword">Opnieuw proberen</Link>
				</div>
			</FormContainer>);
	}

	return (
		<FormContainer title="Voltooi Registratie">
			<div className="alert alert-success mt-3 mb-0">
				{t(resetTokenAction.data?.message || "resetTokenAction.data?.message")}
				<br />
				<br />
				<br />
				<LoginButton />
			</div>
		</FormContainer >);
}

export { VerifyEmail };
