import { IUser } from "../Models/User";
import { BaseRepository } from "../_repository";

export interface IForgotPasswordResponse {
	message: string
}

export interface IBasicMessageResponse {
	message: string
}

export interface IResetTokenRequest {
	token: string;
	password: string;
	confirmPassword: string;
}

export interface IRegisterRequest {
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	confirmPassword: string;
	acceptTerms: boolean;
}

export interface IVerifyEmailRequest {
	token: string;
}

export class UserRepository extends BaseRepository<IUser>
{
	constructor() {
		super("users");
	}

	useForgotPassword = () => {

		const postDataTransformer = (data: string) => ({ "email": data });

		return this.useApiAction<IForgotPasswordResponse, string>(this.endpoint + "/forgot-password", "POST", postDataTransformer);
	}

	useValidateResetToken = () => {

		//"message": "Token is valid"
		const postDataTransformer = (data: string) => ({ "token": data });

		return this.useApiAction<IBasicMessageResponse, string>(this.endpoint + "/validate-reset-token", "POST", postDataTransformer);
	}

	useResetToken = () => {
		return this.useApiAction<IBasicMessageResponse, IResetTokenRequest>(this.endpoint + "/reset-password", "POST");
	}

	useRegister = () => {
		return this.useApiAction<IBasicMessageResponse, IRegisterRequest>(this.endpoint + "/Register", "POST");
	}

	useVerifyEmail = () => {
		return this.useApiAction<IBasicMessageResponse, IVerifyEmailRequest>(this.endpoint + "/verify-email", "POST");
	}
}

