import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper, config } from '../_helpers';
import { IAuth } from './IRootStore';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || "{}") : null,
        error: null
    } as IAuth
}

function createReducers() {
    return {
        logout
    };

    function logout(state: IAuth) {
        state.user = null;
        localStorage.removeItem('user');
        history.navigate!('/shop/login');
    }
}

function createExtraActions() {
    const userBaseUrl =  config.baseUrl + `/users`;

    return {
        login: login()
    };    

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async (l: { username: string, password: string }) => await fetchWrapper.post(`${userBaseUrl}/authenticate`, { email: l.username, password: l.password })
        );
    }
}

function createExtraReducers() {
    return {
        ...login()
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending as any]: (state) => {
                state.error = null;
            },
            [fulfilled as any]: (state, action) => {
                const user = action.payload;
                
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;

                // get return url from location state or default to home page
                const { from } = history.location!.state || { from: { pathname: '/shop/' } };
                history.navigate!(from);
            },
            [rejected as any]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
