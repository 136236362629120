import { useTranslation } from 'globalImport';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type YupBuilderFields =
	"username" |
	"email" |
	"confirmPassword" |
	"gsm" |
	"firstname" |
	"lastname" |
	"password";

function YupValidationBuilder(...params: YupBuilderFields[]) {
	const { t } = useTranslation();
	const result = {};

	if (params.includes("username")) // Keep in sync with email
		result["username"] = Yup.string()
			.email(t("login.emailNotvalid"))
			.required(t('login.emailIsrequired'));

	if (params.includes("email")) // Keep in sync with username
		result["email"] = Yup.string()
			.email(t("login.emailNotvalid"))
			.required(t('login.emailIsrequired'));

	if (params.includes("password"))
		result["password"] = Yup.string()
			.required(t('login.passwordIsrequired'))
			.min(6, t('login.passwordLength'));

	if (params.includes("confirmPassword"))
		result["confirmPassword"] = Yup.string()
			.required(t("login.passwordConfirmIsrequired"))
			.oneOf([Yup.ref('password')], 'Your passwords do not match.')

	if (params.includes("gsm"))
		result["gsm"] = Yup.string()
			.required(t("login.gsmIsrequired"));

	if (params.includes("firstname"))
		result["firstname"] = Yup.string()
			.required(t("login.firstnameIsrequired"));

	if (params.includes("lastname"))
		result["lastname"] = Yup.string()
			.required(t("login.lastnameIsrequired"));

	return yupResolver(Yup.object().shape(result));
}

export { YupValidationBuilder }