import { ICreateOrderRequest, IOrder } from "../Models/Order";
import { BaseRepository } from "../_repository";

export interface IOrderCreateResponse {
	message: string;
	orderId: number;
	
	paymentUrl: string;
	paymentMethod: "Cash" | "Payconiq" | "Invoice";
}

export class OrderRepository extends BaseRepository<IOrder>
{
	constructor() {
		super("orders");
	}

	useCreate = () => {
		return this.useApiAction<IOrderCreateResponse, ICreateOrderRequest>(this.endpoint + "/create", "POST");
	}
}

