import { config } from "globalImport";
import { MoneyString } from "_components";

function optionUI(o) {
	if (!o.option?.name) return <></>;

	return (<div key={o.optionGroup.id} >{o.optionGroup.name}: {o.option?.name}</div>);
}

export function CartItem({ item }) {
	return (
		<>
			<h4>{item.quantity} - {item.product.name}
				{config.showPrices &&
					<small> x <MoneyString value={item.price} /> = <MoneyString value={item.itemTotal || 0} />
					</small>}
			</h4>

			{item.product.description && <div>{item.product.description}</div>}

			{item.options.map(optionUI)}

			{item.remark && <pre>{item.remark}</pre> }
			
		</>
	);
}

export { FinalizeOrder } from './FinalizeOrder';