import { IProduct } from "..";
import { IOptionGroup, IOptionGroupOption } from "../Models/GroupOption";
import { BaseRepository } from "../_repository";

export type submitOptionModel = {
	groupId: number | string;
	option: IOptionGroupOption;
}

export class GroupOptionRepository extends BaseRepository<IOptionGroup>
{
	constructor() {
		super("productoptions");
	}

	private dynamicEndpoint = (data: submitOptionModel) => this.endpoint + "/" + data.groupId + "/options";
	private postDataTransformer = (data: submitOptionModel) => data.option;

	useGetProducts = (id: number | string) => {

		return this.useApiAction<Array<IProduct>>(this.endpoint + "/" + id.toString() + "/products");
	}

	useCreateOption = () => {
		return this.useApiAction<IOptionGroupOption, submitOptionModel>(this.dynamicEndpoint, "POST", this.postDataTransformer);
	}

	useUpdateOption = () => {
		const urlBuilder = (data: submitOptionModel) => this.endpoint + "/" + data.groupId + "/options/" + data.option.id;
		return this.useApiAction<IOptionGroupOption, submitOptionModel>(urlBuilder, "PUT", this.postDataTransformer);
	}

	useDeleteOption = () => {
		type deleteOptionModel = { groupId: number | string, optionId: number };

		const urlBuilder = (data: deleteOptionModel) => this.endpoint + "/" + data.groupId + "/options/" + data.optionId;
		return this.useApiAction<IOptionGroupOption, deleteOptionModel>(urlBuilder, "DELETE", (d: deleteOptionModel) => null);
	}
}

