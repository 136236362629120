import { useState, mui, useUser, useEffect } from "globalImport";
import { ShowIf } from "../../_components";

export interface IDeliveryAddressForm {
	isComplete: boolean;
	bedrijfsNaam: string;
	city: string;
	street: string;
	streetNumber: string;
}

export interface IDeliveryAddressFormProps {
	onChange: (data: IDeliveryAddressForm) => any;
}

export function DeliveryAddressForm(props: IDeliveryAddressFormProps) {
	const [isDirty, setIsDirty] = useState(false);
	const authUser = useUser();

	const isCompany = authUser?.company && authUser?.company.addresses.length > 0;

	const [formData, setFormData] = useState({
		bedrijfsNaam: authUser?.company?.name,
		city: "Erpe-Mere",
		street: (isCompany && authUser.company.addresses[0].street) || "",
		streetNumber: ""
	} as IDeliveryAddressForm);

	useEffect(() => { updateForm(null); }, []); // Onetime to push default object

	const updateForm = (e) => {
		const data = {
			...formData
		};

		if (e) data[e.target.name] = e.target.value;
		data.isComplete = !!(data.bedrijfsNaam && data.city && data.street && (isCompany || data.streetNumber));

		if (e) {
			setIsDirty(true);
			setFormData(data);
		}
		props.onChange(data);
	}

	return (
		<mui.Grid container spacing={2} >
			<mui.Grid item xs={3} md={3} style={{ textAlign: "end" }}>
				<label>Bedrijfsnaam</label>
			</mui.Grid>
			<mui.Grid item xs={9} md={9}>
				<input
					disabled={isCompany}
					defaultValue={formData["bedrijfsNaam"]}
					type="text"
					name="bedrijfsNaam"
					onChange={updateForm}
					className={`form-control`} />
			</mui.Grid>


			<mui.Grid item xs={3} md={3} style={{ textAlign: "end" }}>
				<label>Straat</label>
			</mui.Grid>
			<mui.Grid item xs={9} md={3}>

				<ShowIf condition={!isCompany}>
					<select id="street" name="street" className={`form-control`} onChange={updateForm}>
						<option value=""></option>
						<option value="Evenbroekveld">Evenbroekveld</option>
						<option value="Industrieweg">Industrieweg</option>
						<option value="Oudenaardsesteenweg">Oudenaardsesteenweg</option>
						<option value="Joseph Cardijnstraat">Joseph Cardijnstraat</option>

					</select>
				</ShowIf>

				<ShowIf condition={!!isCompany}>
					<input
						defaultValue={formData["street"]}
						type="text"
						name="street"
						disabled={true}
						className={`form-control`} />
				</ShowIf>
			</mui.Grid>

			{!isCompany && <>
				<mui.Grid item xs={3} md={3} style={{ textAlign: "end" }}>
					<label>Huisnummer</label>
				</mui.Grid>
				<mui.Grid item xs={9} md={3}>
					<input
						defaultValue={formData["streetNumber"]}
						type="text"
						name="streetNumber"
						onChange={updateForm}
						className={`form-control`} />
				</mui.Grid>
			</>
			}

			<mui.Grid item xs={3} md={3} style={{ textAlign: "end" }}>
				<label>Gemeente</label>
			</mui.Grid>
			<mui.Grid item xs={9} md={3}>
				<input
					defaultValue={formData["city"]}
					type="text"
					name="city"
					disabled={true}
					className={`form-control`} />
			</mui.Grid>

			{isDirty && !formData.isComplete &&
				<mui.Grid item xs={12}>
					* Vull alle velden in om uw bestelling door te sturen.
				</mui.Grid>
			}
		</mui.Grid>

	);
}
