import { useUser } from "../globalImport";

interface IShowIfProps {
	condition: boolean;
}

export function ShowIf(props: React.PropsWithChildren<IShowIfProps>) {
	if (!props.condition) return null;
	return <>{props.children}</>;
}

export function ShowIfAuthenticated(props: React.PropsWithChildren) {
	const authUser = useUser();

	return <ShowIf condition={!!authUser}>{props.children}</ShowIf>
}

export function ShowIfAdmin(props: React.PropsWithChildren) {
	const authUser = useUser();

	return <ShowIf condition={authUser?.role === "Admin"}>{props.children}</ShowIf>
}

export function ShowIfCompany(props: React.PropsWithChildren) {
	const authUser = useUser();

	return <ShowIf condition={!!authUser?.company}>{props.children}</ShowIf>
}

export function ShowIfNotCompany(props: React.PropsWithChildren) {
	const authUser = useUser();

	return <ShowIf condition={!authUser?.company}>{props.children}</ShowIf>
}

export function HideIfCompany(props: React.PropsWithChildren) {
	const authUser = useUser();
	return <ShowIf condition={!authUser?.company}>{props.children}</ShowIf>
}