import React, { useEffect } from 'react';
import { UoW } from './../_data/';
import { Table } from 'react-bootstrap';

export function Accounts() {
    const users = UoW.Users.useGet();
    
    useEffect(() => {

        users.execute();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            {users.isLoading && <div className="spinner-border spinner-border-sm"></div>}
            {users.hasError && <div className="text-danger"> Error loading users : {users.error}</div>}
            {users.hasSuceeded &&
                <Table striped bordered hover style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.data!.map(user =>
                            <tr key={user.id}>
                                <td>
                                    {user.firstName} {user.lastName}
                                    <br/>
                                    <small>
                                        <a href={"mailto:" + user.email}>{user.email}</a>
                                    </small>
                                </td>
                                <td>
                                    {user.gsm &&
                                        <a target="_blank" rel="noreferrer" href={"https://wa.me/" + (user.gsm.indexOf('+') === 0 ? user.gsm : '+32' + user.gsm)}>{user.gsm}</a>}
                                </td>
                                <td>
                                    <small>{user.role}</small>
                                </td>
                            </tr>
                            )}
                    </tbody>
                </Table>
            }
        </div>
    );
}
