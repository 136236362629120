import { UoW, Link, useTranslation, useAuthUser, useEffect, Helpers, } from 'globalImport';

import { FieldValues, useForm } from "react-hook-form";

import { FormContainer, FormInput, FormSubmitButton, ShowErrorMessage, ShowSuceededMessage } from '../_components';
import { YupValidationBuilder } from './Helpers/YupValidationBuilder';

function ForgotPassword() {
	const { t } = useTranslation();
	const auth = useAuthUser();
	const authUser = auth?.user;

	const forgotPasswordAction = UoW.Users.useForgotPassword();
	const email = new URLSearchParams(window.location.search).get("email");

	useEffect(() => {
		// redirect to home if already logged in
		if (authUser) Helpers.history.navigate!('/shop');
	}, [authUser]);

	const formOptions = { resolver: YupValidationBuilder("email") };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, formState } = useForm(formOptions);
	const { errors } = formState;

	async function onSubmit(fields: FieldValues) {
		forgotPasswordAction.execute(fields["email"]);
	}

	return (
		<FormContainer title="login.forgotPassword">
			<form onSubmit={handleSubmit(onSubmit)}>

				<FormInput
					label="login.email"
					name="email"
					defaultValue={email}
					register={register} errors={errors} />

				<FormSubmitButton
					label="login.reset"
					executing={forgotPasswordAction.isLoading} />
					
				<ShowErrorMessage action={forgotPasswordAction} />
				<ShowSuceededMessage action={forgotPasswordAction} />
			</form>

			<div><Link to=".." relative="path">{t("nav.login")}</Link></div>
		</FormContainer>
	)
}

export { ForgotPassword };