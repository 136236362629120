import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import deleteIcon from '@mui/icons-material/Delete';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ShoppingCart from '@mui/icons-material/ShoppingCart';

export const Icons = {
	Add,
	Remove,
	Delete: deleteIcon,
	ExpandMore,
	ShoppingCart
};