import * as React from 'react';

import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../_helpers';
import { IAuth, IRootStore } from '../_store';

export { PrivateRoute };

interface IPrivateRouteProps {
    children: any
}

function PrivateRoute(props: IPrivateRouteProps): any {

    const authUser = useSelector<IRootStore, IAuth | null>(x => x.auth);
    
    if (!authUser || !authUser?.user) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/shop/login" state={{ from: history.location }} />
    }
    // authorized so return child components
    return props.children;
}

