import { useTranslation } from "react-i18next";
import { IApiData } from "../_data/_repository";

function ShowOnError(props: React.PropsWithChildren<{ action: IApiData<any> }>) {
	if (!props.action.hasError) return <></>;
	return <>{props.children}</>;
}

function ShowErrorMessage(props: React.PropsWithChildren<{ action: IApiData<any> }>) {
	const { t } = useTranslation();

	return (
		<ShowOnError {...props}>
			<div className="alert alert-danger mt-3 mb-0">
				{t(props.action.error || "")}
				{props.children && <br />}
				{props.children}
			</div>
			
		</ShowOnError>
	);
}

function ShowOnSuceeded(props: React.PropsWithChildren<{ action: IApiData<any> }>) {
	if (!props.action.hasSuceeded) return <></>;
	return <>{props.children}</>;
}

function ShowSuceededMessage(props: React.PropsWithChildren<{ action: IApiData<any> }>) {
	const { t } = useTranslation();

	let msg = "";
	if (props.action.data) { 
		if (typeof props.action.data === 'string' || props.action.data instanceof String) {
			msg = props.action.data.toString();
		}
		else if (props.action.data.message) {
			msg = props.action.data.message;
		}
		else {
			msg = props.action.data.toString();
		}
	}

	return (
		<ShowOnSuceeded {...props}>
			<div className="alert alert-primary mt-3 mb-0">
				{t(msg)}
				{props.children && <br />}
				{props.children}
			</div>

		</ShowOnSuceeded>
	);
}


export { ShowOnError, ShowErrorMessage, ShowOnSuceeded, ShowSuceededMessage };