import { Table } from 'react-bootstrap';
import { IOrderLine, IOrderLineOption } from '../_data/Models/Order';
import { MoneyString, Trans } from '_components';
import { ICartItem, ICartItemOption } from '../shop/cart/ICartItem';
import { CSSProperties } from 'react';

export interface IOrderDetailLinesProps {
	lines: Array<IOrderLine> | Array<ICartItem>;
	showLines?: boolean;
	showTotal?: boolean;
	showHeader?: boolean;
}

const cssTd = {
	width: "100px"
} as CSSProperties

export function OrderDetailLines(props: IOrderDetailLinesProps) {

	const lines = props.lines;
	if (!lines) return null;

	let price: number = 0;
	lines.forEach(l => price += (l.price * (l.quantity || 1)));

	return (
		<Table bordered hover>
			{props.showHeader && <thead>
				<tr>
					<th></th>
					<th style={cssTd}><Trans value="orders.quantity" /></th>
					<th style={cssTd}><Trans value="orders.totalPrice" /></th>
				</tr>
			</thead>}

			<tbody>
				{props.showLines && lines.map(OrderDetailLine)}

				{props.showTotal && < tr key="total">
					<td>
					</td>
					<td style={cssTd}><Trans value="orders.totalPrice" /></td>
					<td style={cssTd}><MoneyString value={price} /></td>
				</tr>}
			</tbody>
		</Table>
	);
}

function OrderDetailLine<T extends IOrderLine | ICartItem>(l: T) {
	return <tr key={l.id}>
		<td>
			<div><strong>{l.product.name}</strong></div>
			<div><small>{l.product.description}</small></div>

			{l.options && l.options.length > 0 &&
				<ul>
					{l.options.map(OrderDetailLineOption)}
				</ul>}

			{l.remark && <div><pre>{l.remark}</pre></div>}
		</td>
		<td style={cssTd}>{l.quantity} x <MoneyString value={l.price} /></td>
		<td style={cssTd}><MoneyString value={l.price * (l.quantity || 1)} /></td>
	</tr>
}


function OrderDetailLineOption<T extends IOrderLineOption | ICartItemOption>(o: T) {
	if (isOrderLineOption(o)) {

		// o has type IOrderLineOption
		return (<li key={o.id}>
			<span>{o.optionGroupName}: {o.selectedOptionName || <Trans value="orders.emptyOption" />}</span>
		</li>);

	} else {
		// On finalize order don't show not slected options
		if (!o.option?.name) return <></>;

		return (<li key={o.optionGroup.id}>
			<span>{o.optionGroup.name}: {o.option?.name || <Trans value="orders.emptyOption" />}</span>
		</li>);

	}
}

function isOrderLineOption(o: IOrderLineOption | ICartItemOption): o is IOrderLineOption { //magic happens here
	return (o as IOrderLineOption).optionGroupName !== undefined;
}