import * as React from 'react';
import './i18n';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from './_store';
import { App } from './App';

import "react-bootstrap/dist/react-bootstrap.min.js";
import './index.css';
import { CartProvider } from 'react-use-cart';


const container = document.getElementById('root');

if (container && window.location.pathname.toLowerCase().startsWith("/shop")) {
	container.replaceChildren();
	const root = createRoot(container!);

	root.render(
		<React.StrictMode>
			<Provider store={store}>
				<CartProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</CartProvider>
			</Provider>
		</React.StrictMode>
	);

	const tempHideRoot = document.getElementById('tempHideRoot');
	if (tempHideRoot) tempHideRoot.remove();
}