import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Busy, MoneyString, Search } from '_components';
import { IProduct, UoW } from '_data';

export function ProductTable(props: React.PropsWithChildren<{ data: Array<IProduct> | null }>) {
	const [searchTerm, setSearchTerm] = React.useState("");

	if (!props.data) return <Busy />;

	let products = props.data.filter(p => p.inWebshop);
	if (searchTerm) products = products?.filter(p =>
		p.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
		(p.description && p.description.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
	);

	const catergories = products?.map(p => p.category)
		.filter((value, index, array) => array.indexOf(value) === index); // Get distinct

	return (
		<div style={{ width: "100%" }}>
			<Search value={searchTerm} onChange={setSearchTerm} />

			<Table striped bordered hover>
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Description</th>
						<th>Price</th>
						<th>Webshop</th>
						<th style={{
							width: "30px",
						}}>
							<div style={{
								width: "30px",
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis"
							}}>Allow Remarks</div></th>
					</tr>
				</thead>
				<tbody>
					{catergories?.map(c =>
						<React.Fragment key={c}>
							<tr className="table-primary">
								<td colSpan={6} className="text-end">{c}</td>
							</tr>

							{products?.filter(v => v.category === c).map(p =>
								<tr key={p.id}>
									<td><Link to={p.id.toString()}>{p.id}</Link></td>
									<td>{p.name}</td>
									<td>{p.description}</td>
									<td><MoneyString value={p.price} /></td>
									<td>{p.inWebshop &&
										<CheckIcon />
									}
									</td>
									<td>{p.allowRemark &&
										<CheckIcon />
									}
									</td>
								</tr>
							)}
						</React.Fragment>
					)}
				</tbody>
			</Table>

		</div>
	);
}
