import { IProduct } from "./Models/Product";
import { GroupOptionRepository } from "./Repositories/GroupOptionRepository";
import { OrderRepository } from "./Repositories/OrderRepository";
import { UserRepository } from "./Repositories/UserRepository";
import { BaseRepository } from "./_repository";

export class uow {
	Products = new BaseRepository<IProduct>("products");
	Orders = new OrderRepository();
	Users = new UserRepository();
	GroupOptions = new GroupOptionRepository();
}


