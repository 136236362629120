import { config, Models, useTranslation, useCart, mui, useState, nanoid } from 'globalImport';
import { MoneyString, Icons } from '_components';

import { AddItemForm } from './AddItemForm';
import { ICartItem, ICartItemOption } from '../cart/ICartItem';


export function MenuItem(props: { product: Models.IProduct }) {
	const { t } = useTranslation();
	const { addItem } = useCart();
	const [nextId, setNextId] = useState(-1);
	const [open, setOpen] = useState(false);

	const buildDefaultProductOption = (optionGroup: Models.IOptionGroup) => {
		const defaultValues = optionGroup.options.filter(o => o.isDefault);
		
		return {
			optionGroup: optionGroup,
			option: defaultValues.length > 0 ? defaultValues[0] : (optionGroup.isRequired ? optionGroup.options[0] : null)
		} as ICartItemOption
	}

	const defaultCartItem = {
		product: props.product,
		quantity: 1,
		remark: "",
		price: props.product.price,
		itemTotal: props.product.price,
		options: props.product.options
			.filter(g => g.options && g.options.length > 0)
			.map(buildDefaultProductOption)
	} as ICartItem

	const [cartItem, setCartItem] = useState(defaultCartItem);

	const item = props.product;

	const addItemWrapperStep1 = () => setOpen(true);

	const addItemWrapperStep2 = () => {

		addItem( 
			{
				...cartItem,
				id: nanoid()
			} as ICartItem, cartItem.quantity || 1);
		setNextId(nextId - 1);
		handleClose();
	}

	const handleClose = () => {
		setCartItem(defaultCartItem);
		setOpen(false);
	};

	return (
		<>
			<tr onClick={addItemWrapperStep1}>
				<td className="description">
					{item.name}
					{item.description && <>
						<br />
						<small>{item.description}</small>
					</>}
				</td>
				<td className="price">
					{config.showPrices && < MoneyString value={item.price} />}
				</td>
				<td className="actions">
					<mui.IconButton aria-label={t('shop.menuItem.add')} onClick={addItemWrapperStep1}>
						<Icons.Add />
					</mui.IconButton>
				</td>
			</tr>
			{open && 
				<mui.Dialog
					open={open}
					onClose={handleClose}
				>
					<mui.DialogTitle>{t('shop.menuItem.add')} {item.name}<br />
						<small>{item.description}</small></mui.DialogTitle>

					<mui.DialogContent>
						<AddItemForm
							product={item}
							cartItem={cartItem}
							onChange={setCartItem} />
					</mui.DialogContent>
					<mui.DialogActions>
						<mui.Button variant="outlined" onClick={handleClose}>{t('common.cancel')}</mui.Button>
						<mui.Button
							variant="contained"
							endIcon={<Icons.Add />}
							onClick={addItemWrapperStep2}>{t('shop.menuItem.add')}</mui.Button>
					</mui.DialogActions>
				</mui.Dialog>}
		</>
	);
}