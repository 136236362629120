import { Link, useTranslation, useAuthUser, useEffect, Helpers, redux } from 'globalImport';

import { useForm } from "react-hook-form";
import { ThunkDispatch } from '@reduxjs/toolkit';

import { FormContainer, FormInput, FormSubmitButton } from '../_components';
import { YupValidationBuilder } from './Helpers/YupValidationBuilder';




function Login() {
	const { t } = useTranslation();
	const dispatch = redux.useDispatch<ThunkDispatch<any, any, any>>();

	const auth = useAuthUser();
	const authUser = auth?.user;
	const authError = auth?.error;

	useEffect(() => {
		// redirect to home if already logged in
		if (authUser) Helpers.history.navigate!('/shop/');
	}, [authUser]);

	// form validation rules 
	const formOptions = { resolver: YupValidationBuilder("username", "password") };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, formState } = useForm(formOptions);
	const { errors, isSubmitting } = formState;

	function onSubmit(fields: any) {
		//{ username, password }
		return dispatch(redux.authActions.login(fields as { username, password }));
	}

	return (
		<FormContainer title="login.name">
			<div>
				{t("login.noAccountYetMessage")}
				<Link to="/shop/register">{t("login.register.registerButton")}</Link>
			</div>
			<br />
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormInput label="login.email" name="username" register={register} errors={errors} />

				<FormInput label="login.password" name="password" type="password" register={register} errors={errors} />

				<FormSubmitButton
					label="login.name"
					executing={isSubmitting} />

				{authError &&
					<div className="alert alert-danger mt-3 mb-0">{t(authError.message)}</div>
				}
			</form>
			<br />
			<div><Link to="ForgotPassword">{t("login.forgotPasswordQuestion")}</Link></div>
		</FormContainer>
	)
}

export { Login };