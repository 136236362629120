import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Busy, DateTimeString, ErrorMessage, Trans } from '_components';
import { UoW } from './../_data/';

export function Orders() {
	const getAction = UoW.Orders.useGet();

	useEffect(() => {
		getAction.execute();
	}, []);

	
	if (getAction.hasError) return <div><ErrorMessage message={getAction.error} /></div>;
	if (!getAction.hasSuceeded) return <Busy />;

	return (
		<div style={{ width: "100%" }}>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>#</th>
						<th><Trans value="common.date" /></th>
						<th>User</th>
					</tr>
				</thead>
				<tbody>
					{getAction.data?.map(p =>
						<tr key={p.id}>
							<td><Link to={p.id.toString()}>{p.id}</Link></td>
							<td><DateTimeString value={p.createdOn} /> </td>
							<td>{p.createdBy.firstName + " " + p.createdBy.lastName}</td>
						</tr>
					)}
				</tbody>
			</Table>

		</div>
	);
}
