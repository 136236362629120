import { useCart, useEffect, useState, UoW} from 'globalImport';
import { Link,  useLocation,  useNavigate,  useParams } from 'react-router-dom';
import { Busy, DateTimeString, ErrorMessage, FormSection, Line, ShowIf, ShowIfAdmin, Trans } from '_components';
import { Button, ButtonGroup } from '@mui/material';
import { OrderDetailLines } from './OrderDetailLines';
import React from 'react';
import { IOrder } from '../_data/Models/Order';


function ShowIfPaymentIsnotExpired(props: React.PropsWithChildren<{ order: IOrder }>) {
	const [time, setTime] = useState(getCurrentTimeUTC());

	useEffect(() => {
		const interval = setInterval(() => setTime(getCurrentTimeUTC()), 1000);

		return () => clearInterval(interval);
	}, []);

	function getCurrentTimeUTC() {
		var tmLoc = new Date();
		return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
	}

	if (!props.order.onlinePaymentUrlExpiration) return <></>;
	const secondsLeft = Math.floor((Date.parse(props.order.onlinePaymentUrlExpiration) - time) / 1000);
	return <ShowIf condition={secondsLeft > 0}>
		{props.children}
		<ShowIfAdmin>
			<div>Betalingslink is nog {secondsLeft} seconden geldig</div>
		</ShowIfAdmin>
	</ShowIf>
}

export function OrderDetails() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { emptyCart } = useCart();

	const id = useParams().id || 0;
	const showCompletedMessage = state && state.orderCompleted;
	if (showCompletedMessage) {
		emptyCart();
	}

	const getAction = UoW.Orders.useGetById(id);

	useEffect(() => {
		getAction.execute();
	}, []);
	
	if (getAction.hasError) return <div><ErrorMessage message={getAction.error} /></div>;
	if (!getAction.hasSuceeded) return <Busy />;

	const data = getAction.data;
	if (!data) return null;

	const paymentInFinalStatus = data.onlinePaymentStatus === "SUCCEEDED" || data.onlinePaymentStatus === "EXPIRED" || data.onlinePaymentStatus === "CANCELLED";

	return (
		<div className="editForm">
			
			<header>
				{showCompletedMessage && <div className="alert alert-success mt-3 mb-3">
					Bestelling verzonden, Smakelijk.
				</div>}

				{!showCompletedMessage && 
					<ButtonGroup variant="outlined" aria-label="outlined primary button group">
						<Button onClick={() => navigate('/shop/orders')}>To List</Button>
					</ButtonGroup>
				}
				
			</header>

			<div style={{ width: "100%" }}>

				

				<FormSection
					fullWidth={true}
					title={<DateTimeString value={data?.createdOn} />}>



					<div><strong>{data.deliveryType}</strong></div>
					<div>{data.companyName}</div>
					<div>{data.street}</div>
					<div>{data.city}</div>

					<br />
					<ShowIf condition={data.paymentMethod === "Payconiq"}>
						<div>Betaling: {data.paymentMethod}</div>
						<div>Betaling status: <Trans value={"orders.onlinePaymentStatus." + data.onlinePaymentStatus} /></div>

						
						<ShowIf condition={!paymentInFinalStatus}>
							<ErrorMessage message="Uw bestelling werd nog niet doorgegeven. Voer eerst de betaling uit of geef een nieuwe bestelling in.">
								<ShowIfPaymentIsnotExpired order={data}>
									<a href={data.onlinePaymentUrl} rel="noreferrer">
										Betalen <br />								
										<img src="/images/payconiq_logo15.png" alt="Payconiq" />
									</a>
								</ShowIfPaymentIsnotExpired>
							</ErrorMessage>

							

							
						</ShowIf>


					</ShowIf>
					
					<br />

					<OrderDetailLines lines={data.orderLines}
						showHeader
						showLines
						showTotal />

				</FormSection>

			</div>
		</div>
		
	);
}
